import { mapState } from "vuex";
import { toastController } from "@ionic/vue";

export default {
  data: () => ({}),
  computed: mapState({
    isAuth: (state) => state.global.isAuth,
  }),
  methods: {
    async openToast(
      message = "...",
      color = "primary",
      icon = null,
      duration = 2000
    ) {
      const toast = await toastController.create({
        message: message,
        duration: duration,
        icon: icon,
        color: color,
      });
      return toast.present();
    },
  },
};
