import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

// Style
import "@/assets/style/index.scss";

import { createApp } from "vue";
import router from "./router";
import store from "./store";
import mixins from "./mixins";

import App from "./App.vue";

const app = createApp(App)
  .use(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .mixin(mixins);

router.isReady().then(() => {
  app.mount("#app");
});
