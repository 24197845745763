/**
 * Global module
 */
const globalModule = {
  namespaced: true,
  state: {
    isInitialized: false,
    isAuth: false,
    requestAuth: false,
    authCredentials: null,
  },
  mutations: {
    // INIT
    SET_IS_INITIALIZED: (state, init) => {
      state.isInitialized = init;
    },

    // AUTH
    SET_AUTH: (state, auth) => {
      state.isAuth = auth;
    },
    SET_REQUEST_AUTH: (state, requestAuth) => {
      state.requestAuth = requestAuth;
    },
    SET_AUTH_CREDENTIALS: (state, credentials) => {
      state.authCredentials = credentials;
    },
  },
  actions: {
    // Init
    setIsInitialized: (context, init) => {
      context.commit("SET_IS_INITIALIZED", init);
    },

    // Auth
    setAuth: (context, auth) => {
      context.commit("SET_AUTH", auth);
    },
    setRequestAuth: (context, auth) => {
      context.commit("SET_REQUEST_AUTH", auth);
    },
    setAuthCredentials: (context, { username, password }) => {
      context.commit("SET_AUTH_CREDENTIALS", {
        username: btoa(unescape(encodeURIComponent(username))),
        password: btoa(unescape(encodeURIComponent(password))),
      });
    },
    unsetAuthCredentials: (context) => {
      context.commit("SET_AUTH_CREDENTIALS", null);
    },
  },
};

export default globalModule;
